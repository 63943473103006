<template>
    <div class="usage-bar-wrapper">
        <div class="usage-bar" :class="{ exceeded: limitReached, pro: unlimited  }" :style="{'--width': usage + '%' }"></div>
        <slot></slot>
        <p class="unlimited-text" v-if="unlimited">Unlimited</p>
    </div>
</template>

<script>
    export default {
        name: 'UsageBar',
        props: {
            limit: Number,
            current: Number,
            unlimited: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            limitReached(){
                return this.current >= this.limit;
            },
            usage(){
                if(this.unlimited){
                    return 100;
                }
                const value = Math.round(100 / (this.limit / this.current) || 0);
                return (value < 100) ? value : 100;
            }
        }
    }
</script>

<style scoped>
    .usage-bar-wrapper{
        background: var(--background-accent);
        height: 30px;
        border-radius: 5px;
        position: relative;
    }

    .usage-bar{
        height: 100%;
        width: var(--width);
        background: var(--primary);
        border-radius: 5px;
        transition: width 1s ease;
    }

    .exceeded{
        background: var(--red) !important;
    }

    .pro{
        background: var(--background-accent) !important;
    }

    .unlimited-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }
</style>