<template>
    <div id="sidebar">
        <transition name="sidebar">
            <div class="sidemenu" v-if="!mobile || showSideMenu">
                <div class="sidemenu-top">
                    <font-awesome-icon v-if="mobile && showSideMenu" class="close-icon" icon="times" @click="showSideMenu = false"/>
                    <router-link to="/"><h1>MailPal</h1></router-link>
                </div>
                <div class="settings-sidebar-wrapper">
                    <div class="settings-sidebar-header">
                        <h2>Settings</h2>
                    </div>
                    <div class="settings-sidebar-list">
                        <div class="settings-sidebar-list-item" :class="{ selected: showSettings === 'general' }" @click="changeShowSettings('general')">
                            <font-awesome-icon icon="wrench"/><p>General</p>
                        </div>
                        <div class="settings-sidebar-list-item" :class="{ selected: showSettings === 'domains' }" @click="changeShowSettings('domains')">
                            <font-awesome-icon icon="at"/><p>Domains</p>
                        </div>
                        <div class="settings-sidebar-list-item" :class="{ selected: showSettings === 'account' }" @click="changeShowSettings('account')">
                            <font-awesome-icon icon="user-alt"/><p>Account</p>
                        </div>
                        <div class="settings-sidebar-list-item" :class="{ selected: showSettings === 'subscription' }" @click="changeShowSettings('subscription')">
                            <font-awesome-icon icon="credit-card"/><p>Subscription</p>
                        </div>
                    </div>
                </div>
                <div class="sidemenu-bottom">
                    <div class="sidemenu-bottom-list">
                        <a href="/"><font-awesome-icon icon="home"/>Home</a>
                        <a @click="logout"><font-awesome-icon icon="sign-out-alt"/>Logout</a>
                        <a href="/help"><font-awesome-icon icon="life-ring"/>Help</a>
                    </div>
                </div>
            </div>
        </transition>
        <div v-if="mobile || !showSideMenu" class="menu-icon">
            <font-awesome-icon icon="bars" @click="showSideMenu = true"/>
        </div>
    </div>
</template>

<script>
    //import LoadingIcon from '@/components/LoadingIcon'
    import { Global_Mutation } from '../store/types'
    import { Action } from '../store/auth/types'
    import { Mutation as UserMutation } from '../store/user/types'

    export default {
        name: 'SideMenu',
        data(){
            return {
                showNewCategory: false
            }
        },
        components: {
        },
        computed: {
            showSideMenu: {
                get: function(){
                    return this.$store.state.showSideMenu;
                },
                set: function(newValue){
                    this.$store.commit(Global_Mutation.CHANGE_SHOW_SIDE_MENU, newValue); 
                }
            },
            showSettings: {
                get: function(){
                    return this.$store.state.user.showSettings;
                },
                set: function(newValue){
                    this.$store.commit(UserMutation.CHANGE_SHOW_SETTINGS, newValue); 
                }
            },
            mobile: function(){
                return this.$store.state.mobile;
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            logout: function(){
                this.$store.dispatch(Action.LOGOUT);
            },
            changeShowSettings: function(value){
                this.showSettings = value;
            }
        },
        created(){
            //this.$store.dispatch(Action.GET_CATEGORIES); 
        }
    }
</script>

<style>
    .sidemenu{
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 300px;
        z-index: 1;
        background: var(--background-sidemenu);
        display: flex;
        flex-direction: column;
    }

    a{
        color: var(--font);
        text-decoration: none;
    }

    .menu-icon{
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        cursor: pointer;
    }

    .close-icon{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
        cursor: pointer;
    }

    .sidebar-enter-active {
        animation: move-in 0.4s;
    }
    .sidebar-leave-active {
        animation: move-in 0.4s reverse;
    }
    @keyframes move-in {
        0% {
            left: -300px;
        }
        100% {
            left: 0;
        }
    }

    .sidemenu-top{
        text-align: center;
        border-bottom: 3px solid var(--background);
    }

    .sidemenu-top h1{
        color: var(--font-light);
    }

    ::placeholder {
        color: var(--font);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font);
        font-weight: 700;
    },

    :-ms-input-placeholder {
        color: var(--font);
        font-weight: 700;
    }

    .settings-sidebar-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 85%;
        margin: auto;
    }

    .settings-sidebar-header h2{
        margin-left: 0;
    }

    .settings-sidebar-wrapper{
        overflow-y: auto;
        height: calc(100% - 300px);
    }

    .settings-sidebar-list{
        max-width: 85%;
        margin: auto;
    }

    .sidemenu-bottom{
        border-top: 3px solid var(--background);
        background: var(--background-sidemenu);
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .sidemenu-bottom-list{
        margin-left: 1rem;
        font-size: 18px;
        font-weight: 650;
        color: var(--font-lighter);
    }

    .sidemenu-bottom-list a{
        color: var(--font-lighter);
        text-decoration: none;
        display: block;
        margin: 1rem;
        cursor: pointer;
    }

    .sidemenu-bottom-list svg{
        margin-right: 1rem;
    }

    .settings-sidebar-list-item{
        background: var(--background);
        border-radius: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 0.5rem;
    }

    .settings-sidebar-list-item p{
        margin-left: 3rem;
    }

    .settings-sidebar-list-item svg{
        position: absolute;
        margin-left: 1rem;
    }

    .selected{
        background: var(--background-dark);
    }

</style>