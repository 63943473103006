<template>
    <div class="settings-page">
        <h1>Domains</h1>
        <p>Every MailPal user can use one of the public domains or add their own domain. A custom domain can have any address you want, while the public ones have limited availability.</p>
        <div class="form">
            <div class="form-row">
                <input v-model="domain" placeholder="piedpiper.com">
                <button class="primary-button change-button" @click="addDomain">Add</button>
            </div>
        </div>
        <DomainItem v-for="domainItem in domains" :domain="domainItem" :key="domainItem._id" />
    </div>
</template>

<script>
    import DomainItem from '@/components/domainItem'
    import { Action, /* Mutation */ } from '@/store/domain/types'

    export default {
        name: 'Domains',
        data() {
            return {
                domain: undefined,
                catchAll: false
            }
        },
        computed: {
            domains: function(){
                return this.$store.state.domain.domains
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            addDomain() {
                this.$store.dispatch(Action.ADD_DOMAIN, { name: this.domain }).then(() => {
                    this.domain = undefined
                })
            },
            verifyDomains() {
                this.$store.dispatch(Action.GET_DOMAINS_VERIFY)
            }
        },
        components: {
            DomainItem
        },
        created (){
            this.$store.dispatch(Action.GET_DOMAINS_VERIFY);
        }
    }
</script>

<style scoped>
    .settings-page {
        margin-top: 3rem;
        max-width: 1200px;
    }

    .domain-wrapper {
        background: var(--background-light);
        border-radius: 5px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .domain {
        display: flex;
        
    }

    .domain-info {
        padding: 20px 20px; 
    }

    .domain-info h3 {
        margin: 0;
    }

    .domain-info p {
        padding: 0 10px;
        font-weight: 650;
    }

    .domain-actions {
        margin-left: auto;
        margin-right: 2rem;
    }

    .status {
        margin-top: 1rem;
    }

    .status svg {
        margin-right: 0.5rem;
    }

    .invalid {
        color: var(--red);
    }

    .valid {
        color: var(--primary);
    }

    .dns-wrapper {
        border-top: 3px solid var(--background);
        padding: 0px 20px 20px 20px;
    }

    .dns {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    }

    .dns-item {
        margin:5px;
    }

    .dns-item h4 {
        margin-top: 0;
    }

    .dns-item svg {
        margin-right: 0.5rem;
    }

    .record {
        display: flex;
        align-items: center;
        background: var(--background);
        padding: 10px 20px;
        border-radius: 5px;
    }

    .record-item {
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
    }

    .record-item h4 {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .form{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 2rem;
    }

    .form-row {
        display: flex;
        align-items: center;
    }

    .change-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-left: 1rem;
        width: 120px;
    }

    .form label{
        font-size: 18px;
    }

    .form input{
        border: none;
        border-radius: 5px;
        background: var(--background-sidemenu);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font-light);
        font-weight: 700;
        padding: 15px 15px;
        font-size: 15px;
        width: 100%;
    }

    .form input:focus{
        filter: brightness(105%);
    }

    ::placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    :-ms-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    .options-wrapper{
        color: var(--font-light);
        max-width: 400px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-accent);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: var(--font);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: var(--primary);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--background-accent-hover);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
</style>