<template>
    <main>
        <side-menu-settings />
        <div class="settings-wrapper" :class="{ 'showSideMenu': showSideMenu}">
            <div class="settings-content">
                <General v-if="showSettings === 'general'" />
                <Domains v-else-if="showSettings === 'domains'" />
                <Account v-else-if="showSettings === 'account'" />
                <Subscription v-else-if="showSettings === 'subscription'" />
            </div>
        </div>
    </main>
</template>

<script>
    import SideMenuSettings from '@/components/SideMenuSettings'
    import General from '@/components/settings/general'
    import Domains from '@/components/settings/domains'
    import Account from '@/components/settings/account'
    import Subscription from '@/components/settings/subscription'
    import { Action, Mutation } from '../store/auth/types'
    import { Action as UserAction, Mutation as UserMutation} from '../store/user/types'
    import { Global_Mutation } from '../store/types'

    export default {
        name: 'Settings',
        computed: {
            newAddress: {
                get: function(){
                    return this.$store.state.address.newAddress.address;
                },
                set: function(newValue){
                    this.$store.commit(Mutation.UPDATE_NEW_ADDRESS, newValue); 
                }
            },
            showSettings: {
                get: function(){
                    return this.$store.state.user.showSettings;
                },
                set: function(newValue){
                    this.$store.commit(UserMutation.CHANGE_SHOW_SETTINGS, newValue); 
                }
            },
            showSideMenu: {
                get: function(){
                    return this.$store.state.showSideMenu;
                },
                set: function(newValue){
                    this.$store.commit(Global_Mutation.CHANGE_SHOW_SIDE_MENU, newValue); 
                }
            },
            silent: {
                get: function(){
                    return this.$store.state.user.options.silent;
                },
                set: function(newValue){
                    this.$store.dispatch(UserAction.CHANGE_SILENT, newValue); 
                }
            },
            user: {
                get: function(){
                    return this.$store.state.user;
                }
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            change(){
                this.$store.dispatch(Action.CREATE_ADDRESS);
            },
            save(){
                this.$store.dispatch(Action.CREATE_ADDRESS);
            },
        },
        components: {
            SideMenuSettings,
            Domains,
            General,
            Account,
            Subscription
        },
        created (){
            addEventListener('resize', () => {
                this.$store.commit(Global_Mutation.CHANGE_MOBILE, innerWidth <= 1300);
            })
            //this.$store.dispatch(Action.GET_ADDRESSES);
            document.body.parentNode.style.background = "var(--background)";
            this.$store.dispatch(UserAction.GET_USER);
        },
        watch: {
            showSideMenu: function(newValue){
                if(newValue){
                    document.body.style.overflowY = "hidden";
                }else{
                    document.body.style.overflowY = null;
                }
            }
        }
    }
</script>

<style>
    .settings-wrapper {
        margin-left: 300px;
        transition: all 0.4s;
        transition-delay: 0s;
    }

    .settings-content {
        max-width: 85%;
        margin: auto;
    }

    @media only screen and (max-width: 1300px) {
        .settings-wrapper{
            margin-left: 0px;
            transition: all 0.4s;
            transition-delay: 0.2s;
        }
        
    }

    .showSideMenu{
        opacity: 0.2;
    }

    .tooltip {
        display: block !important;
        z-index: 10000;
    }

    .tooltip .tooltip-inner {
        background: var(--background-dark);
        color: var(--font);
        border-radius: 5px;
        padding: 5px 10px 4px;
    }

    .tooltip .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: var(--background-dark);
        z-index: 1;
    }

    .tooltip[x-placement^="top"] {
        margin-bottom: -5px;
    }

    .tooltip[x-placement^="top"] .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }

    .tooltip[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    .tooltip[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
</style>