<template>
    <div class="settings-page">
        <h1>Account</h1>
        <div class="form">
            <label for="email">Email address</label>
            <input placeholder="richard@piedpiper.com" v-model="user.email" id="email"/>
            <span class="info"><font-awesome-icon icon="question" class="form-info-icon"/>Used for logins and forwards</span>
            <div class="submit">
                <button class="primary-button change-button" @click="changeEmail">Change<font-awesome-icon icon="arrow-right" class="change-arrow"/></button>
            </div>
        </div>
        <div class="account-stats">
            <h3>Last Login</h3>
            <span>{{displayDate(user.lastLogin)}} - {{new Date(user.lastLogin)}}</span>
            <h3>Registered since</h3>
            <span>{{displayDate(user.created)}} - {{new Date(user.created)}}</span>
        </div>
        <div class="danger-zone">
            <h3>Danger Zone</h3>
            <div class="submit">
                <button class="primary-button delete-button" @click="deleteAccount">Delete account<font-awesome-icon icon="trash" class="change-arrow"/></button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Action } from '@/store/auth/types'
    import { Action as UserAction } from '@/store/user/types'

    export default {
        name: 'Account',
        computed: {
            user: {
                get: function(){
                    return this.$store.state.user;
                }
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            changeEmail(){
                this.$store.dispatch(Action.CHANGE_EMAIL);
            },
            deleteAccount(){
                this.$store.dispatch(Action.DELETE_ACCOUNT);
            },
        },
        created (){
            this.$store.dispatch(UserAction.GET_USER);
        }
    }
</script>

<style scoped>
    .settings-page {
        margin-top: 3rem;
    }

    .submit{
        display: flex;
        justify-content: center;
    }

    .submit button{
        align-self: center;
    }

    .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active {
        transform: scale(0.95);
    }

    .change-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }

    .danger-zone{
        background: #E1707926;
        border: 1px solid var(--red-dark);
        border-radius: 10px;
        padding: 20px 20px;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .danger-zone h3{
        margin: 0;
    }

    .delete-button {
        background: var(--red);
        color: var(--font);
        font-size: 15px;
        border-bottom: 5px solid var(--red-dark);
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .change-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .form{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 400px;
    }

    .form label{
        font-size: 18px;
    }

    .form input{
        border: none;
        border-radius: 5px;
        background: var(--background-sidemenu);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font-light);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .form input:focus{
        filter: brightness(105%);
    }

    ::placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    :-ms-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    .account-stats span{
        color: var(--font-light)
    }

    .info{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .form-info-icon{
        margin-right: 5px;
    }
</style>