<template>
    <div class="subscription-list">
        <div class="subscription-item" v-if="!hideFree">
            <div class="subscription-item-select" :class="{ 'selected-subscription': user.subscription.name === 'free' }" >
                <p>Starter</p>
                <span>Free</span>
            </div>
            <ul class="subscription-item-list">
                <li>3 addresses</li>
                <li>20 emails per month</li>
            </ul>
        </div>
        <div class="subscription-item">
            <div class="subscription-item-select" :class="{ 'selected-subscription': user.subscription.name === 'pro' }">
                <p>Pro</p>
                <div class="subscription-item-select-price">
                    <span>3.50 $</span>
                    <p>per month</p>
                </div>
            </div>
            <ul class="subscription-item-list">
                <li>unlimited addresses</li>
                <li>unlimited emails per month</li>
                <li>browser extension</li>
            </ul>
        </div>
        <div class="subscription-item">
            <div class="subscription-item-select" :class="{ 'selected-subscription': user.subscription.name === 'dev' }">
                <p>Developer</p>
                <div class="subscription-item-select-price">
                    <span>4.00 $</span>
                    <p>per month</p>
                </div>
            </div>
            <ul class="subscription-item-list">
                <li>everything from Pro</li>
                <li>access to developer API</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { Action as UserAction } from '../store/user/types'

    export default {
        name: 'SubscriptionList',
        props: {
            hideFree: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            user: {
                get: function(){
                    return this.$store.state.user;
                }
            },
        },
        created (){
            this.$store.dispatch(UserAction.GET_USER);
        },
    }
</script>

<style scoped>
    .subscription-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .subscription-item-select{
        display: flex;
        align-items: center;
        background: var(--background-accent);
        padding: 10px 10px;
        border-radius: 5px;
        width: 140px;
        height: 45px;
        cursor: pointer;
        border: 2px solid var(--background-accent);
    }

    .selected-subscription{
        border: 2px solid var(--primary);
    }

    .subscription-item-select p{
        margin: 0;
        color: var(--font-light);
    }

    .subscription-item-select span{
        margin-left: auto;
        color: var(--primary);
        font-weight: 700;
    }

    .subscription-item-select-price{
        margin-left: auto;
    }

    .subscription-item-select-price p{
        font-size: 12px;
        margin: 0;
        color: var(--font);
    }
</style>