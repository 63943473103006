<template>
    <div class="settings-page">
        <h1>Subscription</h1>
        <p>Current Subscription</p>
        <subscription-list />
        <div class="danger-zone">
            <h3>Danger Zone</h3>
            <div class="submit">
                <button class="primary-button delete-button" @click="changeSubscription">Change subscription<font-awesome-icon icon="exchange-alt" class="change-arrow"/></button>
                <button class="primary-button delete-button" @click="cancelSubscription">Cancel subscription<font-awesome-icon icon="ban" class="change-arrow"/></button>
            </div>
        </div>
    </div>
</template>

<script>
    import SubscriptionList from '@/components/SubscriptionList'
    import { Action as UserAction} from '@/store/user/types'

    export default {
        name: 'Subscription',
        computed: {
            user: {
                get: function(){
                    return this.$store.state.user;
                }
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            changeSubscription(){
                this.$store.dispatch(UserAction.CHANGE_SUBSCRIPTION);
            },
            cancelSubscription(){
                this.$store.dispatch(UserAction.CANCEL_SUBSCRIPTION);
            }
        },
        components: {
            SubscriptionList
        },
        created (){
            this.$store.dispatch(UserAction.GET_USER);
        }
    }
</script>

<style scoped>
    .settings-page {
        margin-top: 3rem;
    }

    .submit{
        display: flex;
        justify-content: center;
    }

    .submit button{
        align-self: center;
    }

    .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active {
        transform: scale(0.95);
    }

    .change-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }

    .danger-zone{
        background: #E1707926;
        border: 1px solid var(--red-dark);
        border-radius: 10px;
        padding: 20px 20px;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .danger-zone h3{
        margin: 0;
    }

    .delete-button {
        background: var(--red);
        color: var(--font);
        font-size: 15px;
        border-bottom: 5px solid var(--red-dark);
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .change-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .info{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .subscription p{
        font-size: 18px;
    }
</style>