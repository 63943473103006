<template>
   <div class="settings-page">
        <h1>General</h1>
        <h3>Options</h3>
        <div class="options-wrapper">
            <p>Silently drop incoming emails</p>
            <label class="switch">
                <input type="checkbox" v-model="silent">
                <span class="slider round"></span>
            </label>
            <span class="info"><font-awesome-icon icon="question" class="form-info-icon"/>Only affects deactivated addresses</span>
        </div>
        <h3>Usage</h3>
        <usage-wrapper />
        <h3>App Version</h3>
        <p>v{{ version }}</p>
    </div>
</template>

<script>
    import UsageWrapper from '@/components/Usage'
    import { Action as UserAction} from '@/store/user/types'

    export default {
        name: 'General',
        computed: {
            silent: {
                get: function(){
                    return this.$store.state.user.options.silent;
                },
                set: function(newValue){
                    this.$store.dispatch(UserAction.CHANGE_SILENT, newValue); 
                }
            },
            status: function(){
                return this.$store.state.status;
            },
            version: function(){
                return this.$store.state.appVersion;
            },
        },
        components: {
            UsageWrapper
        },
        created (){
            this.$store.dispatch(UserAction.GET_USER);
        }
    }
</script>

<style scoped>
    .settings-page {
        margin-top: 3rem;
    }

    .submit{
        display: flex;
        justify-content: center;
    }

    .submit button{
        align-self: center;
    }

    .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active {
        transform: scale(0.95);
    }

    .change-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }

    .change-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .info{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .form-info-icon{
        margin-right: 5px;
    }

    .options-wrapper{
        color: var(--font-light);
        max-width: 400px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-accent);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: var(--font);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: var(--primary);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--background-accent-hover);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
</style>