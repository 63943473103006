<template>
    <div class="domain-wrapper">
        <div class="domain">
            <div class="domain-info">
                <h3>{{domain.name}}</h3>
                <div v-if="domain.verified" class="status valid">
                    <font-awesome-icon icon="check-circle"/>
                    <span>Valid DNS configuration</span>
                </div>
                <div v-else class="status invalid">
                    <font-awesome-icon icon="times-circle"/>
                    <span>Invalid DNS configuration</span>
                </div>
            </div>
            <div class="domain-actions">
                <div v-if="!domain.verified" class="check">
                    <button class="primary-button check-button" @click="checkDomain" >Check again</button>
                </div>
                <div class="options-wrapper" v-tooltip="{ content: 'Automatically create addresses that don\'t exist.'}">
                    <p>Catch All</p>
                    <label class="switch">
                        <input type="checkbox" v-model="catchAll">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div v-if="!domain.verified" class="dns-wrapper">
            <p>Set the following records on your DNS provider:</p>
            <div class="dns">
                <div class="dns-item">
                    <h4><font-awesome-icon style="color: var(--primary);" icon="check-circle"/>Expected Records</h4>
                    <div class="record">
                        <div class="record-item">
                            <h4>Type</h4>
                            <span v-for="record in domain.expected" :key="record.exchange">MX</span>
                        </div>
                        <div class="record-item">
                            <h4>Name</h4>
                            <span v-for="record in domain.expected" :key="record.exchange">{{domain.name}}</span>
                        </div>
                        <div class="record-item">
                            <h4>Value</h4>
                            <span v-for="record in domain.expected" :key="record.exchange">{{record.exchange}}.</span>
                        </div>
                        <div class="record-item">
                            <h4>Priority</h4>
                            <span v-for="record in domain.expected" :key="record.exchange">{{record.priority}}</span>
                        </div>
                    </div>
                </div>
                <div class="dns-item">
                    <h4><font-awesome-icon style="color: var(--red);" icon="times-circle"/>Current Records</h4>
                    <div class="record">
                        <div class="record-item">
                            <h4>Type</h4>
                            <span v-for="record in domain.current" :key="record.exchange">MX</span>
                        </div>
                        <div class="record-item">
                            <h4>Name</h4>
                            <span v-for="record in domain.current" :key="record.exchange">{{domain.name}}</span>
                        </div>
                        <div class="record-item">
                            <h4>Value</h4>
                            <span v-for="record in domain.current" :key="record.exchange">{{record.exchange}}.</span>
                        </div>
                        <div class="record-item">
                            <h4>Priority</h4>
                            <span v-for="record in domain.current" :key="record.exchange">{{record.priority}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Action } from '@/store/domain/types'
    export default {
        name: 'DomainItem',
        computed: {
            catchAll: {
                get: function(){
                    return this.domain.catchAll
                },
                set: function(newValue){
                    this.$store.dispatch(Action.CHANGE_DOMAIN, { id: this.domain._id, catchAll: newValue }); 
                }
            },
        },
        methods: {
            checkDomain() {
                this.$store.dispatch(Action.GET_DOMAINS_VERIFY)
            }
        },
        props: [ 'domain' ]
    }
</script>

<style scoped>
    .domain-wrapper {
        background: var(--background-light);
        border-radius: 5px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .domain {
        display: flex;
        
    }

    .domain-info {
        padding: 20px 20px; 
    }

    .domain-info h3 {
        margin: 0;
    }

    .domain-info p {
        padding: 0 10px;
        font-weight: 650;
    }

    .domain-actions {
        margin-left: auto;
        margin-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .status {
        margin-top: 1rem;
    }

    .status svg {
        margin-right: 0.5rem;
    }

    .invalid {
        color: var(--red);
    }

    .valid {
        color: var(--primary);
    }

    .dns-wrapper {
        border-top: 3px solid var(--background);
        padding: 0px 20px 20px 20px;
    }

    .dns {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    }

    .dns-item {
        margin:5px;
    }

    .dns-item h4 {
        margin-top: 0;
    }

    .dns-item svg {
        margin-right: 0.5rem;
    }

    .record {
        display: flex;
        align-items: center;
        background: var(--background);
        padding: 10px 20px;
        border-radius: 5px;
    }

    .record-item {
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
    }

    .record-item h4 {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .check {
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .check-button {
        background: var(--background);
        color: var(--font);
        font-size: 14px;
        padding: 10px 20px;
    }

    .delete {
        margin-right: 1rem;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .delete svg {
        cursor: pointer;
        font-size: 23px;
        color: var(--font);
    }

    .options-wrapper{
        color: var(--font-light);
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .options-wrapper p {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-accent);
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: var(--font);
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: var(--primary);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--background-accent-hover);
    }

    input:checked + .slider:before {
        transform: translateX(20px);
    }
</style>