<template>
    <div class="usage-wrapper">
        <usage-bar :limit="user.quota.emails" :current="user.stats.forwarded" :unlimited="user.subscription.name !== 'free' && user.loaded" />
        <p>{{user.stats.forwarded + '/' + user.quota.emails}} forwarded emails since {{new Date(user.stats.start).toLocaleDateString()}} <span v-if="exceedsEmails" class="info"><font-awesome-icon icon="question" class="info-icon"/>All further emails will be blocked</span></p>
        <usage-bar :limit="user.quota.addresses" :current="user.addresses" :unlimited="user.subscription.name !== 'free' && user.loaded" />
        <p>{{user.addresses + '/' + user.quota.addresses}} addresses <span v-if="exceedsAddress" class="info"><font-awesome-icon icon="question" class="info-icon"/>Upgrade to Pro to create unlimited addresses</span></p>
    </div>    
</template>

<script>
    import UsageBar from '@/components/atoms/UsageBar'

    export default {
        name: 'Usage',
        components: {
            UsageBar
        },
        computed: {
            exceedsAddress(){
                return this.user.addresses >= this.user.quota.addresses
            },
            exceedsEmails(){
                return this.user.stats.forwarded >= this.user.quota.emails
            },
            user(){
                return this.$store.state.user;
            },
        }
    }
</script>

<style scoped>
    .usage-wrapper{
        color: var(--font-light);
        max-width: 400px;
    }

    .info{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .info-icon{
        margin-right: 5px;
    }
</style>